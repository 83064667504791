import React from 'react';
import { Container, Typography, Button } from '@mui/material';
import { Link } from 'react-scroll';
import { motion } from 'framer-motion';
import { GitHub, LinkedIn } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXing } from '@fortawesome/free-brands-svg-icons'; // Import the Xing icon from Font Awesome

function Home() {
  return (
    <Container 
      id="home" // ID für das Scrollen zur Home-Sektion
      maxWidth="md" 
      style={{ 
        textAlign: 'center', 
        paddingTop: '12rem', // Erhöht das Padding oben
        paddingBottom: '12rem', // Erhöht das Padding unten
        minHeight: '100vh', // Stellt sicher, dass der Bereich mindestens die Bildschirmhöhe einnimmt
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
        <Typography
          variant="h1"
          color="primary"
          style={{
            textShadow: '0px 4px 12px rgba(0, 255, 136, 0.8)', // Enhanced neon shadow
            color: '#00FF88',
          }}
        >
          Vladimir Gaus
        </Typography>
      </motion.div>
      <Typography variant="h5" style={{ color: '#A0A0A0', marginTop: '1rem' }}>
        Cybersecurity Analyst & Data Scientist in Spe
      </Typography>
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        style={{ marginTop: '2rem' }}
      >
        <Link to="projects" smooth={true} duration={1000}>
          <Button
            variant="outlined"
            color="primary"
            style={{
              borderColor: '#00FF88',
              color: '#00FF88',
              boxShadow: '0px 0px 15px rgba(0, 255, 136, 0.5)', // Glowing effect for button
              transition: '0.3s',
            }}
          >
            VIEW MY WORK
          </Button>
        </Link>
      </motion.div>
      <Typography variant="body2" color="textSecondary" style={{ marginTop: '1.5rem', color: '#B0B0B0' }}>
        „Ich bin fasziniert von der Welt der Cybersecurity und Data Science und freue mich darauf,
        in diesen spannenden Bereichen Fuß zu fassen.“
      </Typography>
      <div style={{ marginTop: '3rem' }}>
        <motion.div whileHover={{ scale: 1.2 }} style={{ display: 'inline-block', margin: '0 10px' }}>
          <a href="https://github.com/dein-profil" target="_blank" rel="noopener noreferrer">
            <GitHub style={{ color: '#00FF88', fontSize: '2rem', cursor: 'pointer' }} />
          </a>
        </motion.div>
        <motion.div whileHover={{ scale: 1.2 }} style={{ display: 'inline-block', margin: '0 10px' }}>
          <a href="https://linkedin.com/in/dein-profil" target="_blank" rel="noopener noreferrer">
            <LinkedIn style={{ color: '#00FF88', fontSize: '2rem', cursor: 'pointer' }} />
          </a>
        </motion.div>
        <motion.div whileHover={{ scale: 1.2 }} style={{ display: 'inline-block', margin: '0 10px' }}>
          <a href="https://www.xing.com/profile/dein-profil" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faXing} style={{ color: '#00FF88', fontSize: '2rem', cursor: 'pointer' }} />
          </a>
        </motion.div>
      </div>
    </Container>
  );
}

export default Home;
