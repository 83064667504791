import React, { useState, useEffect } from 'react';
import { Container, Typography, TextField, Button } from '@mui/material';
import axios from 'axios';
import PrivacyCheckbox from './Checkbox';
import CustomModal from './Modal';
import Impressum from './Impressum';
import Datenschutz from './Datenschutz';
import API_BASE_URL from '../config';


function Contact() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '', hCaptchaToken: '', privacyAccepted: false });
  const [status, setStatus] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openImpressum, setOpenImpressum] = useState(false);
  const [openDatenschutz, setOpenDatenschutz] = useState(false);

  useEffect(() => {
    const loadHcaptchaScript = () => {
      const script = document.createElement('script');
      script.src = "https://js.hcaptcha.com/1/api.js";
      script.async = true;
      document.body.appendChild(script);
    };
    loadHcaptchaScript();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    setFormData({ ...formData, privacyAccepted: e.target.checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.privacyAccepted) {
      setStatus("Bitte akzeptieren Sie das Impressum und die Datenschutzrichtlinien.");
      return;
    }
    setIsSubmitting(true);
    window.hcaptcha.execute();
  };

  const onHcaptchaVerify = async (token) => {
    setFormData((prev) => ({ ...prev, hCaptchaToken: token }));
    try {
      const response = await axios.post(`${API_BASE_URL}/api/contact`, formData);
      setStatus(response.data.message);
    } catch (error) {
      console.error("Fehler beim Senden der Nachricht:", error);
      setStatus("Es gab einen Fehler beim Senden der Nachricht.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div id="contact" style={{ padding: '4rem 0', backgroundColor: 'rgba(18, 18, 18, 0.9)', display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <Container maxWidth="sm" style={{ backgroundColor: '#1e1e1e', borderRadius: '10px', padding: '2rem', boxShadow: '0px 0px 20px rgba(0, 255, 136, 0.3)' }}>
        <Typography variant="h4" style={{ textAlign: 'center', color: '#00FF88', marginBottom: '1.5rem', fontWeight: 'bold' }}>
          Kontakt
        </Typography>
        <form onSubmit={handleSubmit} style={{ display: 'flex', flexDirection: 'column', gap: '1.2rem' }}>
          <TextField name="name" label="Name" fullWidth variant="outlined" onChange={handleChange} InputLabelProps={{ style: { color: '#A8A8A8' } }} InputProps={{ style: { color: '#00FF88' } }} sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#00FF88' },
              '&:hover fieldset': { borderColor: '#00FF88', boxShadow: '0px 0px 10px rgba(0, 255, 136, 0.4)' },
              '&.Mui-focused fieldset': { borderColor: '#00FF88' },
            },
          }}/>
          <TextField name="email" label="Email" fullWidth variant="outlined" onChange={handleChange} InputLabelProps={{ style: { color: '#A8A8A8' } }} InputProps={{ style: { color: '#00FF88' } }} sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#00FF88' },
              '&:hover fieldset': { borderColor: '#00FF88', boxShadow: '0px 0px 10px rgba(0, 255, 136, 0.4)' },
              '&.Mui-focused fieldset': { borderColor: '#00FF88' },
            },
          }}/>
          <TextField name="message" label="Nachricht" fullWidth multiline rows={4} variant="outlined" onChange={handleChange} InputLabelProps={{ style: { color: '#A8A8A8' } }} InputProps={{ style: { color: '#00FF88' } }} sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': { borderColor: '#00FF88' },
              '&:hover fieldset': { borderColor: '#00FF88', boxShadow: '0px 0px 10px rgba(0, 255, 136, 0.4)' },
              '&.Mui-focused fieldset': { borderColor: '#00FF88' },
            },
          }}/>
          <PrivacyCheckbox checked={formData.privacyAccepted} onChange={handleCheckboxChange} onOpenImpressum={() => setOpenImpressum(true)} onOpenDatenschutz={() => setOpenDatenschutz(true)}/>
          <div className="h-captcha" data-sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY} data-callback={onHcaptchaVerify}></div>
          <Button type="submit" variant="contained" fullWidth disabled={isSubmitting} style={{
            backgroundColor: '#00FF88', color: '#121212', fontWeight: 'bold', padding: '0.8rem', borderRadius: '5px', transition: 'all 0.3s ease',
          }} sx={{
            '&:hover': { backgroundColor: '#00CC76' }, '&:disabled': { backgroundColor: '#555' },
          }}>{isSubmitting ? 'Senden...' : 'Nachricht senden'}</Button>
        </form>
        {status && <Typography style={{ color: status.includes('Fehler') ? '#FF0000' : '#00FF88', marginTop: '1rem', textAlign: 'center' }}>{status}</Typography>}
      </Container>
      <CustomModal open={openImpressum} onClose={() => setOpenImpressum(false)} title="Impressum">
        <Impressum />
      </CustomModal>
      <CustomModal open={openDatenschutz} onClose={() => setOpenDatenschutz(false)} title="Datenschutz">
        <Datenschutz />
      </CustomModal>
    </div>
  );
}

export default Contact;
