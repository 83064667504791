import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';

function Header() {
  return (
    <AppBar position="fixed" style={{ backgroundColor: '#121212' }}>
      <Toolbar style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h6" style={{ color: '#00FF88', cursor: 'pointer' }}>
          Cybersecurity & Data Science | Vladimir Gaus
        </Typography>
        <div>
          <ScrollLink to="home" smooth duration={1000} offset={-70}>
            <Button color="inherit">Home</Button>
          </ScrollLink>
          <ScrollLink to="about" smooth duration={1000} offset={-70}>
            <Button color="inherit">Über mich</Button>
          </ScrollLink>
          <ScrollLink to="projects" smooth duration={1000} offset={-70}>
            <Button color="inherit">Projekte</Button>
          </ScrollLink>
          <ScrollLink to="certificates" smooth duration={1000} offset={-70}>
            <Button color="inherit">Zertifikate</Button>
          </ScrollLink>
          <ScrollLink to="skills" smooth duration={1000} offset={-70}>
            <Button color="inherit">Skills</Button>
          </ScrollLink>
          <ScrollLink to="blog" smooth duration={1000} offset={-70}>
            <Button color="inherit">Blog</Button>
          </ScrollLink>
          <ScrollLink to="contact" smooth duration={1000} offset={-70}>
            <Button color="inherit">Kontakt</Button>
          </ScrollLink>
        </div>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
