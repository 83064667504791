// src/App.js
import React from 'react';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import CookieBanner from './components/CookieBanner'; // Importiere den CookieBanner
import './global.css';

function App() {
  return (
    <div>
      <Header />
      <Home />
      <About />
      <Projects />
      <Contact />
      <Footer />
      <CookieBanner /> {/* Stellt sicher, dass der CookieBanner angezeigt wird */}
    </div>
  );
}

export default App;
