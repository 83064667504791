// src/components/Footer.js
import React, { useState } from 'react';
import { Container, Typography, Box, Link } from '@mui/material';
import CustomModal from './Modal';
import Impressum from './Impressum';
import Datenschutz from './Datenschutz';

function Footer() {
  const [openModal, setOpenModal] = useState({ impressum: false, datenschutz: false });

  const handleOpen = (type) => {
    setOpenModal({ ...openModal, [type]: true });
  };

  const handleClose = (type) => {
    setOpenModal({ ...openModal, [type]: false });
  };

  return (
    <Box sx={{ backgroundColor: '#121212', color: '#00FF88', padding: '1rem', textAlign: 'center', marginTop: '2rem' }}>
      <Container maxWidth="md">
        <Typography variant="body2">© 2024 Vladimir Gaus - Cybersecurity Analyst & Data Scientist in Spe</Typography>
        <Typography variant="body2" style={{ marginTop: '0.5rem' }}>
          Kontakt: venic89@gmail.com
        </Typography>
        <Typography variant="body2" style={{ marginTop: '0.5rem' }}>
          <Link href="#" onClick={() => handleOpen('impressum')} style={{ color: '#00FF88' }}>Impressum</Link> | <Link href="#" onClick={() => handleOpen('datenschutz')} style={{ color: '#00FF88' }}>Datenschutz</Link>
        </Typography>
      </Container>
      <CustomModal open={openModal.impressum} onClose={() => handleClose('impressum')} title="Impressum">
        <Impressum />
      </CustomModal>
      <CustomModal open={openModal.datenschutz} onClose={() => handleClose('datenschutz')} title="Datenschutz">
        <Datenschutz />
      </CustomModal>
    </Box>
  );
}

export default Footer;
