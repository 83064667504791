// src/components/About.js
import React from 'react';
import { Typography, Container, Avatar } from '@mui/material';
import { motion } from 'framer-motion';
import meinFoto from '../assets/profile-image.png'; // Bildpfad anpassen

function About() {
  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <Container id="about" style={{ padding: '4rem 0', textAlign: 'center', backgroundColor: 'rgba(18, 18, 18, 0.9)' }}>
      <motion.div initial="hidden" animate="visible" variants={fadeInUp} transition={{ duration: 0.6 }}>
        <Typography
          variant="h4"
          style={{
            marginBottom: '2rem',
            color: '#00FF88',
          }}
        >
          Über mich
        </Typography>

        <Avatar
          src={meinFoto}
          alt="Vladimir Gaus"
          style={{
            width: '150px',
            height: '150px',
            margin: '0 auto 1.5rem',
            boxShadow: '0px 4px 10px rgba(0, 255, 136, 0.5)',
          }}
        />

        <Typography
          variant="body1"
          style={{
            color: '#A8A8A8',
            maxWidth: '800px',
            margin: '0 auto',
          }}
        >
          Hallo! Ich bin Vladimir Gaus, ein angehender Cybersecurity Analyst und Data Scientist mit einer Leidenschaft
          für die Welt der Daten und Cybersicherheit. Ich freue mich darauf, mein Wissen und meine Fähigkeiten in
          diesen spannenden Bereichen weiterzuentwickeln und einen wertvollen Beitrag zu leisten.
        </Typography>
      </motion.div>
    </Container>
  );
}

export default About;
