// src/components/CookieBanner.js
import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';
import CustomModal from './Modal';
import Datenschutz from './Datenschutz';

function CookieBanner() {
  const [showBanner, setShowBanner] = useState(false);
  const [openDatenschutz, setOpenDatenschutz] = useState(false);

  useEffect(() => {
    // Überprüft, ob die Zustimmung bereits im LocalStorage gespeichert ist
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShowBanner(true);
    } else {
      // Lädt das hCaptcha-Skript, falls die Zustimmung schon erteilt wurde
      loadHCaptchaScript();
    }
  }, []);

  const loadHCaptchaScript = () => {
    // Funktion, um das hCaptcha-Skript hinzuzufügen
    const hcaptchaScript = document.createElement('script');
    hcaptchaScript.src = "https://js.hcaptcha.com/1/api.js";
    hcaptchaScript.async = true;
    document.body.appendChild(hcaptchaScript);
  };

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowBanner(false);
    loadHCaptchaScript(); // Lädt das hCaptcha-Skript nach Zustimmung
  };

  return (
    <>
      {showBanner && (
        <Box sx={{ position: 'fixed', bottom: 0, width: '100%', backgroundColor: '#121212', padding: '1rem', textAlign: 'center', color: '#00FF88', zIndex: 1000 }}>
          <Typography variant="body2">
            Diese Website verwendet Cookies, um Ihre Erfahrung zu verbessern. Weitere Informationen finden Sie in unserer{' '}
            <span onClick={() => setOpenDatenschutz(true)} style={{ color: '#00CCFF', textDecoration: 'underline', cursor: 'pointer' }}>
              Datenschutzerklärung
            </span>.
          </Typography>
          <Button onClick={handleAccept} sx={{ backgroundColor: '#00FF88', color: '#121212', marginTop: '0.5rem' }}>
            Akzeptieren
          </Button>
        </Box>
      )}
      <CustomModal open={openDatenschutz} onClose={() => setOpenDatenschutz(false)} title="Datenschutz">
        <Datenschutz />
      </CustomModal>
    </>
  );
}

export default CookieBanner;
