// src/components/Projects.js
import React, { useEffect, useState } from 'react';
import { Typography, Card, CardContent, Grid, Button } from '@mui/material';
import axios from 'axios';
import { motion } from 'framer-motion';
import API_BASE_URL from '../config';  // Importiere die API-Basis-URL

function Projects() {
  const [projects, setProjects] = useState([]);
  const [certificates, setCertificates] = useState([]);
  const [skills, setSkills] = useState([]);
  const [blogPosts, setBlogPosts] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await axios.get(`${API_BASE_URL}/api/projects`);
        const certificateResponse = await axios.get(`${API_BASE_URL}/api/certificates`);
        const skillResponse = await axios.get(`${API_BASE_URL}/api/skills`);
        const blogResponse = await axios.get(`${API_BASE_URL}/api/blogposts`);

        setProjects(projectResponse.data);
        setCertificates(certificateResponse.data);
        setSkills(skillResponse.data);
        setBlogPosts(blogResponse.data);
      } catch (error) {
        console.error("Fehler beim Abrufen der Daten:", error);
      }
    };

    fetchData();
  }, []);

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const categorizedSkills = skills.reduce((acc, skill) => {
    if (!acc[skill.category]) acc[skill.category] = [];
    acc[skill.category].push(skill);
    return acc;
  }, {});

  for (const category in categorizedSkills) {
    categorizedSkills[category].sort((a, b) => b.level - a.level);
  }

  return (
    <div style={{ padding: '6rem 0', backgroundColor: 'rgba(18, 18, 18, 0.9)' }}>
      {/* Projekte */}
      <div id="projects">
        <motion.div initial="hidden" animate="visible" variants={fadeInUp} transition={{ duration: 0.6 }}>
          <Typography variant="h4" style={{ marginBottom: '2rem', textAlign: 'center', color: '#00FF88' }}>
            Meine Projekte
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          {projects.map((project) => (
            <Grid item xs={12} sm={6} md={4} key={project._id}>
              <motion.div initial="hidden" whileInView="visible" viewport={{ once: true }} variants={fadeInUp} transition={{ duration: 0.5 }}>
                <Card style={{ backgroundColor: '#1f1f1f', color: '#00FF88', boxShadow: '0px 4px 10px rgba(0, 255, 136, 0.2)', padding: '1rem', height: '100%' }}>
                  <CardContent>
                    <img src={project.imageUrl} alt={project.title} style={{ width: '100%', height: 'auto', marginBottom: '1rem' }} />
                    <Typography variant="h6" style={{ color: '#00FF88' }}>{project.title}</Typography>
                    <Typography variant="body2" style={{ color: '#A8A8A8' }}>{project.description}</Typography>
                    <Typography variant="body2" style={{ color: '#A8A8A8', marginTop: '0.5rem' }}>Technologien: {project.technologies.join(', ')}</Typography>
                    <Button variant="outlined" color="primary" href={project.githubLink} target="_blank" style={{ marginTop: '1rem' }}>
                      GitHub Link
                    </Button>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}

export default Projects;
