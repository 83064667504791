// src/components/Modal.js
import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: '600px',
  maxHeight: '80vh', // Begrenzte Höhe für Scrollbarkeit
  bgcolor: 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 3,
  borderRadius: '8px',
  overflowY: 'auto', // Scrollbar aktivieren, wenn Inhalt größer ist als der sichtbare Bereich
};

function CustomModal({ open, onClose, title, children }) {
  return (
    <Modal open={open} onClose={onClose} aria-labelledby="modal-title">
      <Box sx={modalStyle}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Typography id="modal-title" variant="h6">
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ maxHeight: '70vh', overflowY: 'auto', paddingRight: '1rem' }}>
          {children}
        </Box>
      </Box>
    </Modal>
  );
}

export default CustomModal;
