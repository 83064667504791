// src/components/Checkbox.js
import React from 'react';
import { FormControlLabel, Checkbox, Link } from '@mui/material';

function PrivacyCheckbox({ checked, onChange, onOpenImpressum, onOpenDatenschutz }) {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} color="primary" />}
      label={
        <span style={{ color: '#A8A8A8' }}>
          Ich akzeptiere die <Link href="#" onClick={onOpenImpressum} style={{ color: '#00FF88' }}>Impressum</Link> und <Link href="#" onClick={onOpenDatenschutz} style={{ color: '#00FF88' }}>Datenschutz</Link>
        </span>
      }
    />
  );
}

export default PrivacyCheckbox;
