import React from 'react';

function Datenschutz() {
  return (
    <div>
      <h2>Datenschutzerklärung</h2>

      <h3>1. Allgemeine Hinweise</h3>
      <p>
        Diese Datenschutzerklärung klärt Nutzer dieser Website über die Art, den Umfang und den Zweck der Erhebung und Verwendung personenbezogener Daten auf. Verantwortlicher für die Datenverarbeitung auf dieser Website ist:
      </p>
      <p>
        Vladimir Gaus<br />
        Hofgeismarer Straße 26<br />
        34393 Grebenstein<br />
        E-Mail: venic89@gmail.com
      </p>

      <h3>2. Erhebung und Speicherung personenbezogener Daten sowie Art und Zweck von deren Verwendung</h3>

      <h4>2.1 Besuch der Website</h4>
      <p>
        Beim Aufrufen dieser Website werden durch den auf Ihrem Endgerät zum Einsatz kommenden Browser automatisch Informationen an den Server dieser Website gesendet. Diese Informationen werden temporär in einem sogenannten Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung gespeichert:
      </p>
      <ul>
        <li>IP-Adresse des anfragenden Rechners</li>
        <li>Datum und Uhrzeit des Zugriffs</li>
        <li>Name und URL der abgerufenen Datei</li>
        <li>Website, von der aus der Zugriff erfolgt (Referrer-URL)</li>
        <li>Verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers</li>
      </ul>
      <p>
        Die genannten Daten werden zu folgenden Zwecken verarbeitet:
      </p>
      <ul>
        <li>Gewährleistung eines reibungslosen Verbindungsaufbaus der Website</li>
        <li>Gewährleistung einer komfortablen Nutzung unserer Website</li>
        <li>Auswertung der Systemsicherheit und -stabilität sowie</li>
        <li>zu weiteren administrativen Zwecken</li>
      </ul>

      <h4>2.2 Nutzung des Kontaktformulars</h4>
      <p>
        Bei Fragen jeglicher Art bieten wir Ihnen die Möglichkeit, mit uns über ein auf der Website bereitgestelltes Formular Kontakt aufzunehmen. Dabei ist die Angabe einer gültigen E-Mail-Adresse erforderlich, damit wir wissen, von wem die Anfrage stammt und um diese beantworten zu können. Weitere Angaben können freiwillig getätigt werden.
      </p>

      <h3>3. Nutzung von Drittanbietern</h3>
      <p>Um die Funktionalität dieser Website zu erweitern, werden Dienste von Drittanbietern eingebunden. Diese Drittanbieter können personenbezogene Daten verarbeiten.</p>

      <h4>3.1 GitHub</h4>
      <p>
        Diese Website wird über GitHub Pages gehostet. GitHub speichert möglicherweise Nutzerdaten, wie z. B. IP-Adressen, wenn Sie die Website besuchen. Mehr Informationen finden Sie in der <a href="https://docs.github.com/en/site-policy/privacy-policies/github-privacy-statement" target="_blank" rel="noopener noreferrer">Datenschutzerklärung von GitHub</a>.
      </p>

      <h4>3.2 MongoDB Atlas</h4>
      <p>
        Die Datenbank für diese Website wird über MongoDB Atlas bereitgestellt. Ihre Daten werden in einer gesicherten Umgebung in der Cloud gespeichert. Mehr Informationen finden Sie in der <a href="https://www.mongodb.com/legal/privacy-policy" target="_blank" rel="noopener noreferrer">Datenschutzerklärung von MongoDB</a>.
      </p>

      <h4>3.3 hCaptcha</h4>
      <p>
        Zum Schutz vor Spam setzen wir hCaptcha ein. Beim Einsatz von hCaptcha können personenbezogene Daten an den Anbieter übermittelt werden. Weitere Informationen finden Sie in der <a href="https://www.hcaptcha.com/privacy" target="_blank" rel="noopener noreferrer">Datenschutzerklärung von hCaptcha</a>.
      </p>

      <h4>3.4 Mailgun</h4>
      <p>
        Für den Versand von E-Mails nutzen wir den Dienst Mailgun. Ihre Daten können an Mailgun in den USA übermittelt werden, wenn Sie uns über das Kontaktformular eine Nachricht senden. Mehr Informationen finden Sie in der <a href="https://www.mailgun.com/privacy-policy" target="_blank" rel="noopener noreferrer">Datenschutzerklärung von Mailgun</a>.
      </p>

      <h4>3.5 IONOS</h4>
      <p>
        Die Domain dieser Website wird über IONOS verwaltet. Dabei werden personenbezogene Daten wie IP-Adressen verarbeitet. Weitere Informationen finden Sie in der <a href="https://www.ionos.de/terms-gtc/terms-privacy/" target="_blank" rel="noopener noreferrer">Datenschutzerklärung von IONOS</a>.
      </p>

      <h3>4. Cookies</h3>
      <p>
        Diese Website verwendet Cookies, um das Nutzererlebnis zu verbessern und die Nutzung bestimmter Funktionen zu ermöglichen. Im Cookie-Banner können Sie der Nutzung von Cookies zustimmen.
      </p>

      <h3>5. Rechte der betroffenen Person</h3>
      <p>
        Sie haben das Recht:
      </p>
      <ul>
        <li>gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen.</li>
        <li>gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen.</li>
        <li>gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen.</li>
        <li>gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.</li>
        <li>gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen.</li>
        <li>gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren.</li>
      </ul>

      <h3>6. Widerspruchsrecht</h3>
      <p>
        Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß Art. 6 Abs. 1 lit. f DSGVO verarbeitet werden, haben Sie das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen.
      </p>

      <h3>7. Aktualität und Änderung dieser Datenschutzerklärung</h3>
      <p>
        Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Oktober 2024. Durch die Weiterentwicklung unserer Website und Angebote darüber oder aufgrund geänderter gesetzlicher beziehungsweise behördlicher Vorgaben kann es notwendig werden, diese Datenschutzerklärung zu ändern.
      </p>
    </div>
  );
}

export default Datenschutz;
